<!--
 * @Author: xiaoxingliang
 * @Date: 2021-12-20 09:10:44
 * @LastEditTime: 2021-12-21 10:35:28
 * @LastEditors: xiaoxingliang
 * @FilePath: \lc_factory\src\views\layout\components\NavMenu.vue
-->
<template>
  <el-menu
    class="lc-menu"
    :unique-opened="true"
    menu-trigger="click"
    background-color="#304150"
    text-color="#f0f0f0"
    :collapse="collapse"
    :default-active="activeMenuId"
  >
    <!-- <SidebarItem :menuData="menuData"/> -->
    <template class="menu-list" v-for="item in menuData">
      <el-submenu :key="item.id" :class="activeMenuId === item.id ? 'active' : 'disactive'" :index="item.id" v-if="item.children" style="padding-left: 0">
        <template slot="title">
          <i :class="item.icon" class="menu-icon"></i>
          <span slot="title" class="menu-name">{{ item.title }}</span>
        </template>
        <template v-for="child in item.children">
          <el-submenu v-if="child.children" :class="activeMenuId === child.id ? 'active' : 'disactive'" :key="child.id" :index="child.id">
            <template slot="title">
              <i :class="child.icon" class="menu-icon"></i>
              <span slot="title" class="menu-name">{{ child.title }}</span>
            </template>
            <template v-for="grandson in child.children">
              <el-submenu v-if="grandson.children" :class="activeMenuId === grandson.id ? 'active' : 'disactive'" :key="grandson.id" :index="grandson.id">
                <template slot="title">
                  <i :class="grandson.icon" class="menu-icon"></i>
                  <span slot="title" class="menu-name">{{ grandson.title }}</span>
                </template>
              </el-submenu>
              <el-menu-item
                v-else
                :key="grandson.id"
                :index="grandson.id"
                @click="handleClick(grandson)"
              >
                <span class="nav-item">
                  <i class="menu-icon" :class="grandson.icon"></i>
                  <router-link
                    class="menu-name"
                    :to="{ path: grandson.url }"
                    slot="title"
                  >{{ grandson.title }}</router-link>
                </span>
              </el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item v-else :key="child.id" :class="activeMenuId === child.id ? 'active' : 'disactive'" :index="child.id" @click="handleClick(child)">
            <span class="nav-item">
              <i class="menu-icon" :class="child.icon"></i>
              <!-- <router-link class="menu-name" :to="{ path: child.url }" slot="title">{{ child.title }}</router-link> -->
              <span class="menu-name" slot="title">{{ child.title }}</span>
            </span>
          </el-menu-item>
        </template>
      </el-submenu>
      <el-menu-item :key="item.id" v-else :class="activeMenuId === item.id ? 'active' : 'disactive'" :index="item.id" @click="handleClick(item)">
        <template>
          <i class="menu-icon" :class="item.icon"></i>
          <span class="menu-name" slot="title">{{ item.title }}</span>
          <!-- <router-link class="menu-name" @click="handleClick(item)" :to="{ path: item.url }" slot="title"></router-link> -->
        </template>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { mapState } from "vuex";
import SidebarItem from "./SideBar";
export default {
  components: {
    // SidebarItem,
  },
  props: {
    //菜单是否展开或收起
    collapse: Boolean,
    //左侧菜单数据
    menuData: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("navBar", {
      activeMenuId: (state) => state.activeMenuId,
    }),
  },
  methods: {
    handleClick(item) {
      // console.log(item)
      this.$store.commit("navBar/SET_ACTIVE_MENU_ID", item.id);
      this.$store.commit("navBar/ADD_VIEW", item);
      if(item.children === undefined || item.children === null) this.$router.push(item.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-name, .menu-icon {
  color: $submenu-color !important;
}
.active {
  background-color: $submenu-active-color !important;
  .menu-name, .menu-icon {
    color: $submenu-active-text-color !important;
  }
}

.lc-menu:not(.el-menu--collapse) {
  width: 200px;
  min-width: 40px;
  // min-height: 400px;
}
.el-menu--collapse {
  width: 40px;
  ::v-deep .el-submenu__title {
    padding: 0;
    padding-left: 10px !important;
    justify-content: center !important;
  }
}
.lc-menu {
  border-right: none !important;
  .menu-icon {
    font-size: 16px;
    // margin-right: 10px;
  }
  .menu-name {
    display: inline-block;
    font-size: 14px;
    flex: 1;
    margin-left: 6px;
    // height: 50px;
  }
  ::v-deep .el-submenu__title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .el-tooltip {
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  ::v-deep .el-submenu__icon-arrow {
    color: $submenu-color !important;
  }
  ::v--deep .el-submenu .el-submenu-item {
    background-color: #1f2d3d!important;
  }
}
</style>

