<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mounted() {
    // console.log(process.env)
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
