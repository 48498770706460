<template>
  <div class="layout">
    <div class="header">
      <div class="header-left">
        <img class="logo" :src="logo" alt="logo" />
        <span class="company-name">{{ companyName }}</span>
        <div class="navbar-box">
          <NavBar />
        </div>
      </div>
      <div class="header-right">
        <Screenfull class="screenfull" />
        <!-- <i class="el-icon-rank" @click="toFullScreen"></i> -->

        <!-- <el-dropdown class="avatar-dropdown" trigger="click" @command="handleCommand">
          <img class="avatar" src="https://www.orgvts.top/static/img/avatar.b89e281.png" />
          <el-dropdown-menu slot="dropdown">
            
            <el-dropdown-item command="">账号:{{userInfo.usrAcct}}</el-dropdown-item>
            <el-dropdown-item command="exit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <el-dropdown style="color: #fff" class="avatar-container" trigger="click">
          <div style="display: flex">
            <div class="avatar-wrapper" style="display: flex">
              <img src="@/assets/img/logo2.png" class="user-avatar" />
              <div>
                <span style="font-size: 14px; text-align: center; margin-top: -25px; margin-left: 5px">{{ realName }}</span>
                <br />
                <span style="font-size: 10px">{{ role === 'BOSS' ? '工地老板' : '工地管理员' }}</span>
              </div>
              <i class="el-icon-caret-bottom" />
            </div>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
              <!-- <router-link class="inlineBlock" to="/">
                <el-dropdown-item> 首页 </el-dropdown-item>
              </router-link> -->
              <el-dropdown-item>
                <span style="display: block" @click="visible = true">切换角色</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item divided>
                <span style="display: block" @click="dialogFormVisible = true">修改密码</span>
              </el-dropdown-item> -->
              <el-dropdown-item divided>
                <span style="display: block" @click="logout">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>
    <div class="container">
      <div class="side" id="side">
        <NavMenu :menuData="menu" :collapse="collapse" />
        <div class="oper-wrapper">
          <el-button class="oper-btn" @click="menuOption">
            <i :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
          </el-button>
        </div>
      </div>

      <div class="content" :class="{ 'show-sideMenu': collapse }">
        <div class="main">
          <!-- <router-view v-slot="Component">
              <keep-alive :include="cachedViews" :exclude="excludeRoutes">
                <component :is="Component"></component>
              </keep-alive>
            </router-view> -->
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
          <!-- <keep-alive :include="cachedViews" :exclude="excludeRoutes">
            <router-view />
          </keep-alive> -->
        </div>
      </div>
    </div>
    <el-dialog title="请选择角色" :visible.sync="visible" width="30%">
      <div class="roleSwitchModal">
        <ul class="flex justify-around text-center mb">
          <li :class="role === 'BOSS' ? 'active' : ''" @click="changeRole('BOSS')">
            <div class="img">
              <img src="@/assets/img/siteboss.png" alt="" />
              <span v-if="role === 'BOSS'" class="passed-icon">
                <div checked="true"></div>
              </span>
            </div>
            <span class="">工地老板</span>
          </li>
          <!-- <li :class="role === 'ADMIN' ? 'active' : ''" @click="changeRole('ADMIN')">
            <div class="img">
              <img src="@/assets/img/siteadmin.png" alt="" />
              <span v-if="role === 'ADMIN'" class="passed-icon">
                <div checked="true"></div>
              </span>
            </div>
            <span class="">工地管理员</span>
          </li> -->
          <!-- <li class="">
          <div class="img"><img src="@/assets/img/placeBoss.png" alt="" /></div>
          <span class="">渣土场老板</span>
        </li> -->
        </ul>
      </div>
      <div class="text-center">
        <el-button style="width: 150px" size="small" type="primary" @click="saveRole">确 定</el-button>
        <br />
        <el-checkbox style="margin-top: 15px" v-model="checked">下次登录记住本次角色选择</el-checkbox>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import logo from '@/assets/img/logo2.png'
import Screenfull from './components/Screenfull'
import NavMenu from './components/NavMenu'
import NavBar from './components/NavBar.vue'
import { routes } from '@/router/index.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import store from '../../store'
export default {
  components: {
    NavMenu,
    NavBar,
    Screenfull
  },
  data() {
    return {
      collapse: false,
      menu: [],
      logo,
      role: store.getters.userType,
      visible: false,
      checked: true,
      companyName: '好运来客户系统',
      excludeRoutes: ['EmptyPage', 'MenuManage', 'Login', 'UserInfo']
    }
  },
  computed: {
    ...mapState({
      visitedViews: state => state.navBar.visitedViews,
      cachedViews: state => state.navBar.cachedViews,
      activeMenuId: state => state.navBar.activeMenuId,
      userInfo: state => state.user.userInfo,
      realName: state => state.user.realName,
      userType: state => state.user.userType
    })
  },
  watch: {
    collapse: {
      handler(newVal) {
        this.setSideDomWidth()
      }
    },
    $route(to, from) {
      // console.log(to)
      this.setActiveMenu(to)
    },
    activeMenuId(val) {}
  },
  activated() {},
  created() {
    this.setMenu(routes)
    this.setActiveMenu(this.$route)
  },
  mounted() {
    this.setSideDomWidth()
  },
  methods: {
    ...mapActions({
      addView: 'navBar/addView',
      addCachedView: 'navBar/addCachedView',
      setActiveMenuId: 'navBar/setActiveMenuId',
      closeVisitedView: 'navBar/closeVisitedView',
      logout: 'logout'
    }),
    saveRole() {
      this.$store.commit('SET_USERTYPE', this.role)
      this.visible = false
      if (this.role === 'BOSS') {
        this.$router.push({ path: '/layout/home' }).catch(() => {})
      } else if (this.role === 'ADMIN') {
        this.$router.push({ path: '/layout/map' }).catch(() => {})
      }
      this.closeVisitedView(null)
      this.setMenu(routes)
    },
    changeRole(role) {
      this.role = role
    },
    // 获取是否菜单权限
    hasRoutePermission(route) {
      const role = store.getters.userType || 'BOSS'
      if (!route.meta) return true
      if (!route.meta.roles) return true
      return route.meta.roles.includes(role)
    },

    // 设置菜单
    setMenu(routes) {
      console.log('routes', routes)
      let menuList = JSON.parse(JSON.stringify(routes))
      // deep是最大嵌套层级
      const formatterMenu = (routes = [], deep) => {
        deep++
        routes = routes.map(item => {
          if (!item.hidden) {
            item = {
              id: item.id,
              url: item.path,
              name: item.name,
              title: item.title,
              icon: item.icon,
              children: item.children
            }
          } else {
            return {}
          }
          if (deep < 2 && item.children && item.children.length) {
            item.children = formatterMenu(
              item.children.filter(m => !m.hidden),
              deep
            )
          }
          return item
        })
        return routes
      }
      this.menu = menuList
        .filter(route => route.hidden === false && this.hasRoutePermission(route))
        .map((menu, index) => {
          if (menu.children) {
            menu.children = formatterMenu(
              menu.children.filter(item => !item.hidden && this.hasRoutePermission(item)),
              0
            )
          }
          if (!menu.hidden) {
            if (menu.children.length > 1) {
              return {
                id: menu.id,
                url: menu.path,
                name: menu.name,
                title: menu.title,
                icon: menu.icon,
                children: menu.children || ''
              }
            } else {
              return menu.children[0]
            }
          }
        })
    },
    // 激活菜单
    setActiveMenu(to) {
      // 菜单只有一级
      let routePath = to.path || this.$route.path
      routePath = routePath.split('/')
      const [topPath, subPath] = [routePath[1], routePath[2]]
      let menu
      menu = this.menu.find(v => v.url === `/${topPath}`)
      if (!menu) {
        menu = this.menu.find(v => v.url === `/${topPath}/${subPath}`)
      }

      // console.log(menu, topPath, subPath)
      if (menu) {
        // 如果有孩子，选中孩子
        if (menu.children) {
          const subMenu = menu.children.find(v => v.url === `/${topPath}/${subPath}`)
          if (subMenu) {
            this.setActiveMenuId(subMenu)
            this.addView(subMenu)
            this.addCachedView(subMenu)
          }
        } else {
          if (menu) {
            this.setActiveMenuId(menu)
            this.addView(menu)
            this.addCachedView(menu)
          }
        }
      }
    },
    //点击头像触发
    handleCommand(command) {
      if (command === 'userInfo') {
        let userInfo = routes.find(item => item.path === '/userInfo')
        this.$store.commit('navBar/SET_ACTIVE_MENU_ID', userInfo.id)
        this.$store.commit('navBar/ADD_VIEW', { ...userInfo })
        this.$router.push({ path: userInfo.path })
      } else if (command === 'exit') {
        this.logout()
        this.$store.commit('navBar/DELETE_ALL')
        this.$store.commit('navBar/DEL_ALL_CACHED_VIEWS')
        this.$router.push({ path: '/login' })
      }
    },
    //设置side的宽度
    setSideDomWidth() {
      let sideDom = document.getElementById('side')
      // if (this.collapse) {
      //   sideDom.style.width = '64px';
      // } else {
      //   sideDom.style.width = "200px";
      // }
    },
    //控制左侧菜单收缩和展开
    menuOption() {
      this.collapse = !this.collapse
      this.$store.commit('SET_COLLAPSE', this.collapse)
    },
    // 全屏模式
    toFullScreen() {
      this.showHeader = false
      let elem = document.body
      elem.webkitRequestFullScreen
        ? elem.webkitRequestFullScreen()
        : elem.mozRequestFullScreen
        ? elem.mozRequestFullScreen()
        : elem.msRequestFullscreen
        ? elem.msRequestFullscreen()
        : elem.requestFullScreen
        ? elem.requestFullScreen()
        : alert('浏览器不支持全屏')
    },
    // 退出全屏
    exitFullscreen() {
      this.showHeader = true
      let elem = parent.document
      elem.webkitCancelFullScreen
        ? elem.webkitCancelFullScreen()
        : elem.mozCancelFullScreen
        ? elem.mozCancelFullScreen()
        : elem.cancelFullScreen
        ? elem.cancelFullScreen()
        : elem.msExitFullscreen
        ? elem.msExitFullscreen()
        : elem.exitFullscreen
        ? elem.exitFullscreen()
        : alert('切换失败,可尝试Esc退出')
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .header {
    width: 100%;
    height: $headerHeight;
    background-color: $navBar-color;
    box-shadow: 0 2px 8px #f0f1f2;
    // padding-bottom: 0 20px;
    display: flex;
    .header-left {
      width: 200px;
      flex: 7;
      display: flex;
      align-items: center;
      .logo {
        width: 40px;
        height: 30px;
        padding-left: 5px;
      }
      .company-name {
        width: 160px;
        color: #fff;
        font-size: 14px;
        text-align: center;
      }
      .menu-opt {
        display: block;
        width: 20px;
        font-size: 20px;
        color: #fff;
        margin-left: 20px;
        cursor: pointer;
      }
      .navbar-box {
        flex: 3;
        height: 100%;
      }
    }
    .header-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      .screenfull {
        margin-right: 20px;
        display: flex;
      }
      .avatar-dropdown {
        display: flex;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
  }
  .container {
    width: 100%;
    height: calc(100% - 44px);
    display: flex;
    .side {
      // width: 200px;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
      position: relative;
      border-right: 1px solid #ccc;
      flex-shrink: 0;
      background: $bg-menu-color;
    }
    .oper-wrapper {
      width: 16px;
      height: 50px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 20px;
      right: 10px;

      .oper-btn {
        width: 16px;
        height: 60px;
        padding: 0;
        background: transparent;
        border: 0;
        i {
          color: #fff;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
    .side::-webkit-scrollbar {
      display: none;
    }
    .content {
      width: calc(100% - 201px);
      flex: 1;
      height: 100%;
      // background-color: #f0f0f0;
      display: flex;
      flex-direction: column;
      // padding: 5px 5px 0px 5px;
      .main {
        flex: 1;
        // background-color: #f2f2f2;
        height: 100%;
        overflow: auto;
      }
    }
    .show-sideMenu {
      width: calc(100% - 41px);
    }
  }
}
.avatar-wrapper {
  cursor: pointer;
  margin-top: 10px;
  width: 100px;
  position: relative;
  line-height: initial;
  .user-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .el-icon-caret-bottom {
    position: absolute;
    right: -20px;
    top: 10px;
    font-size: 16px;
  }
}
.roleSwitchModal ul {
  margin: 16px 0;
}
.roleSwitchModal ul > li {
  opacity: 0.5;
}
.roleSwitchModal ul > li.active {
  opacity: 1;
}
.roleSwitchModal .mb {
  margin-bottom: 30px;
}
.roleSwitchModal .ml {
  margin-left: 16px;
}
.roleSwitchModal .img {
  position: relative;
  width: 108px;
  height: 108px;
}
.roleSwitchModal .img .passed-icon {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: 3px !important;
  left: 70px !important;
}
.roleSwitchModal .img .passed-icon div {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAEMElEQVRIS6WWfUyVVRzHP+dyGSAN0UtDB8aE/iClcIi89WIvM0BEHYvsjkooMkuNXK5N0Rku6+IamaTIlCW+wHKNICpLRrpk6VjIDJkkw/XGxTShSwORLpx27rPrhXu5lwv9/nue5/f7fp5zzu/lCLwwKeVCYDXwKBANzAF8gX7gGtAKNAD1QohBT5LC00cpZTqww2whubYNmq7Bletg7ofhEZgVAJEGiJsHadGw9H76fXSUA8VCiFsTaU8IlFKGAuVXb7Bq92mob4dROflWhM2EzY9DbgK39D4UCCFOOEe5AKWUD41Kvi79njAFUyuZqi0KgwojRIWwD9gshBi1a4wDSiljh62cXX+S4JqfpooZ7z/TH6pehIcjOSKEyHMBSinvlXAx9zjhdZf/H8wePcMXvngF4u9jmxDiffX+7gqllLUlZ1i169vpwwwzYE4QtF93aMwNgqYCrIZAkoUQP9qAUsq0zhucSvkI/p3GmSmNALWafIgOhbUn4LtOB9QYB2XP0gwk2YE/5FWR/Pk0z00n4NjzkKGqVRVkB2QfcQCFgPNv2n4mXaisNFu4FGPyLvUn2vDdGbBBtQTgcg+kH4R/7oz3zE2AvVnUKGDh/nO8W/jV9M5ufQqYVmqxf/wNyw5Aj+o/ThYcAF07GFDAb4yVpJ66MnXgyoXwSQ746MByG1IPQsef7nXObgQF/CW2mIhf+xyOfnrw14NlyH1wYgTU5YO/r5ZoWRVwTnVVD1aWrQGHQrfjd8fq8DRlwvIFkF8Nzb+5KkSFQMNrMDtQZThs+AyqWibfoZ1pGnBk1lZ0KlDZI/Ohfh2ozLKOgKkRSs44EkrV2unXbW3LZu81wJ7GyWHKY+syDdg3v4jgvttakF4HhU9DwWOg02nvmrpg3UnoG9RqbUmE9v5oM7xR4x1MeRVnasCWJ0qJa+0eH7g0CsrXaJ1DWe+AlhApkdqzqjXjUbDebcuTgz9dqwEPb6nl5cMXXAMMgXDgGUh9YPw3VWupZTAwPDlkrEdnoQZ8rqGD6rGdwVnm1RTYtRxU9nqqNU/4xeHQuJEOBbxnZJTu2D0EKTF39uBcKFkNm2o815q7+L1ZtsG8095Liysu8PZbtVPbIm+9w4Lh4hYG/fRE2oEG6wg/P7UfwyWztzLe+6nGnhlDkRDinbHz0Nh5k6onP3ZtvN5Lu3rmJcCHWag5lCiEGHK+Yuxr6mLTmsqpZ+BEP5WxACpz6NX7kCSEsE1IZ6Aq9UMtv/PSC8fBbJn+2vKTbFPkL72OdDXp7Ururonbegcp2v4l+upWrV96a+HBYFoBK2Js25gthLg6NtbtRVhKuQQobe8h8dB5qGsDe/tzhiuR+HmQEw/GxQz46fkAMKkzm8jX489LKVOB/FFJepuZQNXeui3aSFJDVTXxReEQEoiaqFXqAi2EuOlO9D8SHYnVFq8Y3AAAAABJRU5ErkJggg==')
    no-repeat;
  background-size: 100%;
}
.roleSwitchModal .text {
  font-size: 14px;
  font-weight: 500;
  color: #8d909b;
}
.text-center {
  text-align: center;
}
.justify-around {
  justify-content: space-around;
}
.flex {
  display: flex;
}
ul {
  list-style: none;
}
</style>
