<template>
  <div class="side-bar">
    <template class="menu-list" v-for="(item,index) in menuData">
      <el-submenu :key="item.id" :index="item.id" v-if="item.children" style="padding-left: 0">
        <template slot="title" @click="handleClick(item)">
          <i :class="item.icon" class="menu-icon"></i>
          <span>{{ item.name }}</span>
        </template>
        <SidebarItem :menuData="item.children" />
      </el-submenu>
      <el-menu-item :key="index" v-else :index="item.id" @click="handleClick(item)">
        <span class="nav-item" >
          <i class="menu-icon" :class="item.icon"></i>
          <router-link :to="{ path: item.url }" slot="title">{{ item.name }}</router-link>
        </span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    menuData: Array,
  },
  data() {
    return {
    };
  },
  methods:{
    handleClick(item){
      this.$store.commit('navBar/SET_ACTIVE_MENU_ID',item.id)
      this.$store.commit('navBar/ADD_VIEW',item)
    }
  }
};
</script>

<style lang="scss" scoped>
.side-bar {
  background-color: $bg-color;
  .el-submenu,
  .el-menu-item {
    .menu-icon {
      font-size: 20px;
      margin-right: 10px;
    }
    .nav-item{
      display: block;
      height: 50px;
      a{
        display: inline-block;
        height: 50px;
      }
    }
    
  }
}
</style>
