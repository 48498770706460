import Vue from 'vue'
import Vuex from 'vuex'
const path = require('path')
import getters from './getters'

Vue.use(Vuex)

const state = {}

const modulesFiles = require.context("./modules",true,/\.js$/);
const modules = modulesFiles.keys().reduce((modules,modulePath)=>{
  const dirName = path.dirname(modulePath);
  let key;
  if(dirName==='.'){
    key = path.basename(modulePath,".js");
  }else {
    if(path.basename(modulePath,".js")=== "index"){
      key = dirName.match(/[a-z0-9]/gi).join("")
    }
  }
  if(key){
    const value = modulesFiles(modulePath);
    modules[key] = value.default;
  }
  return modules
},{})

export default new Vuex.Store({
  modules,
  state,
  getters,
})
