<template>
  <div class="login">
    <div class="background" v-if="!!code" v-loading.fullscreen.lock="fullscreenLoading"></div>
    <div class="content" v-else>
      <!-- <img src="@/assets/images/5.png" alt="" class="login-logo" /> -->
      <div class="login-logo">
        <span style="font-size:30px;margin-top:200px;">协同&emsp;适配&emsp;便利&emsp;敏捷</span>
        <br>
        <div style="text-align: center;margin-top:30px;width:300px;">
          <img src="@/assets/img/download.png" style="height:200px;width:200px;">
          <br>
          <span>扫码下载移动端app</span>
          </div>
      </div>
      <div class="login-rt">
        <img src="@/assets/img/logo.png" alt="" class="login-logo1" />
        <div class="login-rt-content">
          <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
            <div class="content-title">好运来客户系统</div>
            <!-- <div class="content-title1">Terminal Management System</div> -->
            <el-form-item v-if="form.pwdLogin === true" prop="phone">
              <el-input v-model.trim="form.phone" clearable placeholder="请输入手机号" auto-complete="new-phone">
                <i slot="prefix" style="font-size: 25px; color: #888" class="el-input__icon el-icon-user-solid"></i
              ></el-input>
            </el-form-item>
            <el-form-item v-if="form.pwdLogin === false" prop="phone">
              <el-input v-model.trim="form.phone" clearable placeholder="请输入手机号" auto-complete="new-phone">
                <i slot="prefix" style="font-size: 25px; color: #888" class="el-input__icon el-icon-user-solid"></i>
                <!-- <template slot="append"></template> -->
              </el-input>
              <el-link
                :disabled="sendCode"
                style="width: 70px; position: absolute; margin-top: 5px; z-index: 999; right: 30px; color: #247eff"
                :underline="false"
                @click="getPhoneCode"
                >{{ codeTxt }}</el-link
              >
            </el-form-item>
            <el-form-item v-if="form.pwdLogin === false" prop="code">
              <el-input v-model.trim="form.code" clearable placeholder="请输入短信验证码" auto-complete="new-phone">
                <i slot="prefix" style="font-size: 25px; color: #888" class="el-input__icon el-icon-chat-dot-square"></i
              ></el-input>
            </el-form-item>
            <el-form-item v-if="form.pwdLogin === true" prop="password">
              <el-input
                v-model.trim="form.password"
                show-password
                placeholder="请输入密码"
                clearable
                auto-complete="new-password"
                @keyup.enter.native="submit"
              >
                <i slot="prefix" style="font-size: 25px; color: #888" class="el-input__icon el-icon-lock"></i
              ></el-input>
            </el-form-item>
            <div class="remember-password">
              <el-checkbox v-model="isRememberPassword" @change="setRememberPassword">记住密码</el-checkbox>
              <el-tooltip class="tip" effect="dark" content="非工作电脑请勿保存" placement="right">
                <i class="el-icon-info"></i>
              </el-tooltip>
              <el-link style="margin-left: 15px; color: #247eff" @click="form.pwdLogin = !form.pwdLogin">切换登陆方式</el-link>
            </div>
          </el-form>
          <el-button type="primary" class="login-btn" :loading="logining" @click="submit" @keyup.enter="submit">登录</el-button>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>Copyright © 辰凡运输, All Rights Reserved.</p>
      <p>杭州辰凡汽车运输管理有限公司</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { AES_ECB_ENCRYPT, AES_ECB_DECRYPT } from '@/utils/util.js'
import { getSmsCode } from '@/api/login.js'
import store from '@/store'
import Cookie from 'js-cookie'
export default {
  layout: 'empty',
  data() {
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    const validatPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      logining: false,
      form: {
        phone: '',
        password: '',
        code: '',
        pwdLogin: true //true-手机号密码登陆 false-手机验证码登录
      },
      reg: reg,
      codeTxt: '获取验证码',
      t: 60, //倒计时剩余时间
      sendCode: false, //是否禁用点击获取验证码按钮

      rules: {
        phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }, { validator: validatPhone }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }]
      },
      fullscreenLoading: true,
      code: this.$route.query.code || '',
      isRememberPassword: window.localStorage.getItem('isRememberPassword') === 'true' // 是否记住密码
    }
  },

  computed: {},
  watch: {
    isRememberPassword(newVal) {
      window.localStorage.setItem('isRememberPassword', newVal)
    }
  },

  created() {
    let role = Cookie.get('hyl-pc-usertype')
    if (!role) {
      Cookie.set('hyl-pc-usertype', 'BOSS')
    }
    if (window.localStorage.getItem('hyl_pc_user')) {
      const [phone, password] = window.localStorage.getItem('hyl_pc_user').split(',')
      this.form.phone = phone
      this.form.password = AES_ECB_DECRYPT(password, process.env.VUE_APP_SECRET_KEY)
    }
    console.log(this.form)
  },
  beforeDestroy() {
    window.localStorage.setItem('isRememberPassword', this.isRememberPassword)
  },

  methods: {
    ...mapActions({
      login: 'login'
      // getLoginToken: 'user/getLoginToken',
      // getUserInfo: 'user/getUserInfoByToken',
    }),
    ...mapMutations({
      setUserInfo: 'SET_USER_INFO'
    }),

    async submit() {
      // 登录

      // try {
      await this.$refs.form.validate()
      const res = await this.login(this.form)
      if (res.code === 200) {
        this.$message.success('登录成功！')
        // this.setUserInfo = res.data.baseConsignor

        // window.localStorage.setItem('userInfo', JSON.stringify(res.data.baseConsignor))
        const password = AES_ECB_ENCRYPT(this.form.password, process.env.VUE_APP_SECRET_KEY)
        if (this.isRememberPassword) {
          window.localStorage.setItem('hyl_pc_user', this.form.phone + ',' + password)
        } else {
          window.localStorage.setItem('hyl_pc_user', '')
        }
        let role = store.getters.userType || 'BOSS'
        if (role === 'BOSS') {
          this.$router.push({ path: '/layout/home' }).catch(() => {})
        } else if (role === 'ADMIN') {
          this.$router.push({ path: '/layout/map' }).catch(() => {})
        }
      } else {
        this.$message.error(res.msg)
        return
      }
      // } catch (err) {
      //   console.log(err)
      // }
    },
    // 记住密码
    setRememberPassword(val) {
      console.log(val)
    },
    // 获取短信验证码
    getPhoneCode() {
      let params = {
        phone: this.form.phone,
        type: 'LOGIN'
      }
      if (!this.reg.test(this.form.phone)) {
        this.$message.error('请输入正确的手机号码')
        return
      }
      this.sendCode = true

      this.t = 60 //60s
      let _this = this
      var auth_timetimer = setInterval(() => {
        _this.t--
        _this.codeTxt = _this.t + '秒'
        if (_this.t <= 0) {
          this.sendCode = false
          _this.codeTxt = '获取验证码'
          clearInterval(auth_timetimer)
        }
      }, 1000)
      getSmsCode(params).then(res => {
        if (res.code === 200) {
          this.$message.success('获取验证码成功')
        } else {
          this.$message.error('获取验证码失败')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__append {
  background-color: #ffffff !important;
  border: none;
}
.login {
  width: 100%;
  height:100%;
  position: relative;
  background: $primary-color;
  .content {
    width: 1000px;
    height: 80%;
    min-height: 500px;
    position: absolute;
    top: 10%;
    // bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    // border-radius: 8px;
    // box-shadow: 0px 2px 20px 0px #e2eeff;
    // background: #ffffff;
    display: flex;

    .login-logo1 {
      height: 80px;
      margin-top: -30px;
      background: rgb(252, 252, 252);
      border-radius: 1px solid rgb(210, 197, 197);
      border-radius: 50%;
      margin-bottom: 2%;
    }

    .login-logo {
      margin-top:5%;
      width: 70%;
      height: auto;
      color: #ffffff;
      font-size: 24px;
    }

    .login-rt {
      width: 450px;
      height: 70%;
      margin-top:30px;
      position: relative;
      box-shadow: 0px 2px 20px 0px #e2eeff;
      border-radius: 15px;
      background: #fff;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;

      .login-rt-content {
        width: 80%;
        height: 100%;

        ::v-deep .el-form-item {
          display: flex;
          flex-direction: column;
          .el-form-item__content {
            margin-left: 0 !important;
            margin-bottom: 10px;
            .el-input__inner {
              border: none;
              border-bottom: 1px solid #d4d4d4;
              padding-left: 55px;
            }
          }
        }

        .content-title {
          // height: 90px;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: $primary-color;
          line-height: 8vh;
          text-align: center;
          // margin-bottom: 25px;
          // text-align: center;
          // font-size: 32px;
          // font-weight: 500;
          // color: #2d303b;
          // margin-bottom: 60px;
        }
        .content-title1 {
          // height: 90px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: $primary-color;
          line-height: 5vh;
          margin-bottom: 3vh;
          text-align: center;
          // text-align: center;
          // font-size: 32px;
          // font-weight: 500;
          // color: #2d303b;
          // margin-bottom: 60px;
        }

        .input-style {
          margin-bottom: 40px;

          p {
            color: #2d303b;
          }

          input {
            width: 100%;
            height: 35px;
            line-height: 35px;
            // background: transparent;
            border: none;
            border-bottom: 1px solid #d4d4d4;
          }
        }

        .login-btn {
          width: 100%;
          height: 50px;
          margin-top: 2.5%;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    height: 8%;
    position: absolute;
    // top: 720px;
    bottom: 4%;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 12px;
      color: #cccccc;
      margin: 5px;
    }
  }
  .remember-password {
    // position: absolute;
    // left: 0;
    // bottom: 10px;
    display: flex;
    align-items: center;
    .tip {
      margin-left: 10px;
    }
  }
}
</style>
