import router from '@/router/index'
const state = {
  activeMenuId: "0",
  visitedViews: [],
  refreshUrl: "",
  cachedViews: [],
};
const mutations = {
  ADD_VIEW: (state, view) => {
    let flag = state.visitedViews.map((item) => item.id).includes(view.id);
    if (flag) {
      // state.visitedViews.map((item, index) => {
      //   if (item.id == view.id) {
      //     state.visitedViews.splice(index, 1);
      //     state.visitedViews.unshift(view);
      //   }
      // });
      state.activeMenuId = view.id;
    } else {
      // state.visitedViews.unshift(view);
      state.visitedViews.push(view);
    }
  },
  RESET_ACTIVE_MENU: (state, view) => {
    state.visitedViews.map((item, index) => {
      if (item.id == view.id) {
        state.visitedViews.splice(index, 1);
        state.visitedViews.unshift(view);
      }
    });
  },
  SET_ACTIVE_MENU_ID: (state, id) => {
    state.activeMenuId = id;
  },
  // 关闭当前页
  DELETE_VIEW: (state, view) => {
    state.visitedViews.map((item, index) => {
      if (item.id === view.id) {
        state.visitedViews.splice(index, 1);
      }
    });
    if (state.activeMenuId == view.id) {
      const length = state.visitedViews.length;
      state.activeMenuId = length ? state.visitedViews[length - 1].id : null;
      router.push(state.visitedViews[length - 1].url)
    }
  },
  // 关闭其他页
  DELETE_OTHER: (state, item) => {
    state.visitedViews = state.visitedViews.filter(item => state.activeMenuId === item.id);
  },
  // 关闭全部
  DELETE_ALL: (state, id) => {
    state.visitedViews = [];
  },
  SET_REFRESH_PAGE: (state, url) => {
    state.refreshUrl = url;
  },
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return
    // if (!view.meta.hidden) {
      state.cachedViews.push(view.name)
    // }
  },
  DEL_CACHED_VIEW: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    index > -1 && state.cachedViews.splice(index, 1)
  },
  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1)
    } else {
      state.cachedViews = []
    }
  },
  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = []
  },
  DEL_ALL_VISITED_VIEW: state => {
    state.visitedViews = []
  }
};
const actions = {
  addView({ commit }, view) {
    commit("ADD_VIEW", view);
  },
  addCachedView({ commit }, view) {
    commit("ADD_CACHED_VIEW", view);
  },
  setActiveMenuId({ commit }, view) {
    // console.log(view)
    commit("SET_ACTIVE_MENU_ID", view.id);
  },
  closeVisitedView({ commit }, view) {
    // console.log(view)
    commit("DEL_ALL_VISITED_VIEW");
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
