import axios from 'axios'
// import qs from 'qs';
import { Message } from 'element-ui'
import router from '@/router'
import { isFormData } from './util'
import { getToken } from '@/utils/auth.js'
import store from '@/store/index.js'

const http = axios.create({
  baseURL: process.env.BASE_URL +'/customer',
  timeout: 20000
  // withCredentials: true
})
//这里简单列出一些常见的http状态码信息，可以自己去调整配置
const httpCode = {
  50001: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  50002: '手机号未注册',
  50003: '手机号被占用',
  50004: '严重码不正确',
  50005: '验证码过期',
  50006: '密码错误',
  50007: '账号不存在'
}
//请求拦截
http.interceptors.request.use(
  config => {
    if (['post', 'put', 'patch', 'delete'].includes(config.method.toLocaleLowerCase())) {
      // config.transformRequest = [
      //   function (data, headers) {
      //     if (isFormData(data)) return data;
      //     return JSON.stringify(data);
      //   },
      // ];
    }
    if (getToken()) {
      config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    return config
  },
  error => {
    return new Promise.reject(error)
  }
)

//响应拦截
http.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response.data)
    }
  },
  error => {
    if (axios.isCancel(error)) {
      console.log('已取消的重复请求：' + error.message)
    }
    // if (error.response) {
    //   const { status, data, config } = error.response
    //   let errorTip = status in httpCode ? httpCode[status] : data.msg
    //   if (status === 401) {
    //     Message({
    //       message: '登录超时,请重新登录!',
    //       type: 'error'
    //     })
    //     store.dispatch('logout').then(() => location.reload())
    //     return Promise.reject(error)
    //   } else {
    //     Message({
    //       message: errorTip,
    //       type: 'error'
    //     })
    //   }
    // }
    return Promise.reject(error)
  }
)

export default http
