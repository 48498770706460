import router from './index.js'
// import { Message } from 'element-ui'

import { getToken } from '@/utils/auth.js'
import store from '@/store'
const whiteList = ['/login', '/common/emptyPage','/download'] // 白名单

router.beforeEach((to, from, next) => {
  console.log(from.path,getToken())
  if (getToken()) {
    // 验证token

    if (to.path === '/login') {
      next()
    } else {
      // if (to.path !== from.path) {
      //   next()
      // }
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // Message.error('登陆过期，请重新登录')
      next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
    }
  }
})

router.afterEach(() => {})
