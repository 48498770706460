import request from '@/utils/http.js'

/**
 * 手机号登录
 * @param {object} data 
 * @returns 
 */
export const loginByPhone = data => {
  return request({
    url: '/login/phone',
    method: 'post',
    data
  })
}

// 验证码登录
export const loginByCode = data => {
  return request({
    url: '/login/sms',
    method: 'post',
    data
  })
}



// 获取登录用户信息
export function getUserInfo() {
  return request({
    url: '/user/info',
    method: 'get'
  })
}

//获取短息验证码
export function getSmsCode(params) {
  return request({
    url: '/sms/smscode',
    method: 'get',
    params: params
  })
}
