import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue'
import Login from '@/views/login/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    id: '11',
    path: '/layout',
    title: '数据概览',
    name: 'LayoutData',
    hidden: false,
    component: Layout,
    meta: { roles: ['BOSS'] },
    redirect: '/layout/home',
    children: [
      {
        id: '111',
        path: '/layout/home',
        title: '数据概览',
        name: 'DataMode',
        hidden: false,
        icon: 'el-icon-s-data',
        meta: { keepAlive: false, roles: ['BOSS'] },
        component: () => import('@/views/dashboard/index')
      }
    ]
  },
  {
    id: '12',
    path: '/layout/map',
    title: '地图模式',
    name: 'LayoutMap',
    hidden: false,
    component: Layout,
    meta: { roles: ['BOSS', 'ADMIN'] },
    redirect: '/layout/map',
    children: [
      {
        id: '121',
        path: '/layout/map',
        title: '地图模式',
        name: 'MapMode',
        hidden: false,
        icon: 'el-icon-s-grid',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/projectAmap/projects')
      }
    ]
  },
  {
    id: '13',
    path: '/layout/routing',
    title: '车辆追踪',
    name: 'Layout',
    hidden: true,
    component: Layout,
    meta: { roles: ['BOSS', 'ADMIN'] },
    children: [
      {
        id: '131',
        path: '/layout/routing',
        title: '车辆追踪',
        name: 'MapGJ',
        hidden: true,
        icon: 'el-icon-s-home',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/projectAmap/routing')
      }
    ]
  },
  {
    id: '14',
    path: '/project',
    title: '工地管理',
    name: 'project',
    hidden: false,
    icon: 'el-icon-s-order',
    meta: { roles: ['BOSS', 'ADMIN'] },
    component: Layout,
    children: [
      {
        id: '141',
        path: '/project/manage',
        title: '工地项目',
        name: 'projectManage',
        hidden: false,
        icon: 'el-icon-s-flag',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/project.vue')
      },
      {
        id: '142',
        path: '/project/order',
        title: '工地工单',
        name: 'projectOrder',
        hidden: false,
        icon: 'el-icon-s-ticket',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/projectOrder.vue')
      },
      {
        id: '143',
        path: '/project/statistics',
        title: '数据统计',
        name: 'projectStatistics',
        hidden: true,
        icon: 'el-icon-edit-outline',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/statistics.vue')
      },
      {
        id: '144',
        path: '/project/personal',
        title: '人员管理',
        name: 'projectPersonal',
        hidden: true,
        icon: 'el-icon-edit-outline',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/personal.vue')
      },
      {
        id: '145',
        path: '/project/config',
        title: '配置管理',
        hidden: true,
        name: 'projectConfig',
        icon: 'el-icon-edit-outline',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/config.vue')
      },
      {
        id: '146',
        path: '/order/recover',
        title: '工单恢复',
        name: 'projectOrderRecover',
        hidden: true,
        icon: 'el-icon-chat-dot-square',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/project/projectOrderRecover.vue')
      }
    ]
  },
  {
    id: '15',
    path: '/settlement',
    name: 'settlementManage',
    title: '结算管理',
    hidden: false,
    meta: { roles: ['BOSS', 'ADMIN'] },
    icon: 'el-icon-s-management',
    component: Layout,
    children: [
      {
        id: '151',
        path: '/settlement/order',
        title: '工单结算',
        name: 'orderSettlementManage',
        hidden: false,
        icon: 'el-icon-s-finance',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/settlement/orderSettlement.vue')
      },
      {
        id: '152',
        path: '/settlement/detail',
        title: '结算记录',
        hidden: false,
        name: 'settlementDetail',
        icon: 'el-icon-s-data',
        meta: { keepAlive: true, roles: ['BOSS', 'ADMIN'] },
        component: () => import('@/views/settlement/settlementExport.vue')
      }
    ]
  },
  {
    id: '16',
    path: '/fs',
    name: 'fs',
    title: '财务报表',
    icon: 'el-icon-s-claim',
    hidden: false,
    meta: { roles: ['BOSS'] },
    component: Layout,
    children: [
      {
        id: '161',
        path: '/fs/project',
        title: '项目统计',
        name: 'fsProject',
        hidden: false,
        icon: 'el-icon-menu',
        meta: { keepAlive: false, roles: ['BOSS'] },
        component: () => import('@/views/financialStm/projectStt.vue')
      },
      {
        id: '162',
        path: '/fs/slag',
        title: '渣土场统计',
        name: 'fsSlag',
        hidden: false,
        icon: 'el-icon-menu',
        meta: { keepAlive: false, roles: ['BOSS'] },
        component: () => import('@/views/financialStm/slagStandStt.vue')
      },
      {
        id: '163',
        path: '/fs/truck',
        title: '车队统计',
        name: 'fsTruck',
        hidden: false,
        icon: 'el-icon-menu',
        meta: { keepAlive: false, roles: ['BOSS'] },
        component: () => import('@/views/financialStm/truckStt.vue')
      },
      {
        id: '164',
        path: '/fs/soilType',
        title: '土类型统计',
        name: 'fsSoilType',
        hidden: false,
        icon: 'el-icon-menu',
        meta: { keepAlive: false, roles: ['BOSS'] },
        component: () => import('@/views/financialStm/soilTypeStt.vue')
      },
      {
        id: '165',
        path: '/fs/settlement',
        title: '结算统计',
        name: 'fsSettlement',
        hidden: false,
        icon: 'el-icon-menu',
        meta: { keepAlive: false, roles: ['BOSS'] },
        component: () => import('@/views/financialStm/settlementStt.vue')
      }
    ]
  },
  // {
  //   id: '17',
  //   path: '/layout',
  //   title: '设备管理',
  //   name: 'LayoutData',
  //   hidden: false,
  //   component: Layout,
  //   meta: { roles: ['BOSS'] },
  //   redirect: '/layout/home',
  //   children: [
  //     {
  //       id: '171',
  //       path: '/layout/home',
  //       title: '抓拍机管理',
  //       name: 'DataMode',
  //       hidden: false,
  //       icon: 'el-icon-s-data',
  //       meta: { keepAlive: false, roles: ['BOSS'] },
  //       component: () => import('@/views/dashboard/index')
  //     }
  //   ]
  // },
  {
    id: '88',
    path: '/common',
    name: 'Common',
    component: Layout,
    hidden: true,
    children: [
      {
        id: '10',
        path: '/common/emptyPage',
        title: '空白页面',
        name: 'EmptyPage',
        icon: 'el-icon-s-home',
        hidden: true,
        component: () => import(/*webpackChunkName: "emptyPage" */ '@/views/empty-page/index.vue')
      }
    ]
  },
  {
    id: '1',
    path: '/',
    redirect: 'login',
    hidden: true
  },
  {
    id: '2',
    path: '/login',
    name: 'Login',
    component: Login,
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 全局守卫
// router.beforeEach((to, from, next) => {
//   //todos:判断是否有token,有继续跳转，无跳转至登陆页
// });
export default router
export { routes }
